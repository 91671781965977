@import url('https://fonts.googleapis.com/css2?family=Rakkas&display=swap');

.App {
  font-family: sans-serif;
  text-align: center;
}

/* ensuring mobile adaptation and no-scroll */
html, body {
  height: 100%;
  height: -webkit-fill-available;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* * {
  font-family: 'Rakkas', cursive;
} */